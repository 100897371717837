/* Google Web Fonts */
@font-face {
    font-family: 'Press Start 2P';
    font-style: normal;
    font-weight: 400;
    src: url('#{$pacmanRootUrl}fonts/PressStart2Play.eot');
    src: url('#{$pacmanRootUrl}fonts/PressStart2Play.eot?iefix') format('eot'),
    url('#{$pacmanRootUrl}fonts/PressStart2Play.woff') format('woff'),
    url('#{$pacmanRootUrl}fonts/PressStart2Play.ttf') format('truetype');
}

/* ---------------- */