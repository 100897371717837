.pacman-container {
    background-color: #F2F2F2;

    &.paused {
        .controlSound {
            top: -30px !important;
        }

        .pacman-container__game {
            #canvas-container {
                background-color: transparent;
            }
        }


    }

    @import 'game/controls';

    .pacman-container__game {

        .content[id*=-content]:not(#game-content) {
            display: none;
            overflow-y: auto;
            overflow-x: hidden;
        }

        * {
            box-shadow: none !important;
        }

        h1, h2, h3, p, div, li {
            font-family: 'Press Start 2P', cursive;
            font-weight: normal;
        }

        .content[id*=-content]:not(#game-content) {
            display: none;
            overflow-y: auto;
            overflow-x: hidden;
        }

        .content img {
            max-width: 100%;
        }

        .pull-right {
            float: right;
        }

        .button {
            background: #c80a0a;
            cursor: pointer;
            padding: 8px;
        }

        #menu-buttons .button {
            background: rgba(0, 0, 0, 0.4);
        }

        #menu-buttons .button:hover, .button:hover {
            background: rgba(255, 255, 255, 0.2);
        }

        .button#back {
            position: relative;
            top: -25px;
            display: inline-block;
        }

        .button#score-submit {
            vertical-align: middle;
        }

        #highscore-form {
            margin-top: 8px;
        }

        input[type="text"]:hover, input[type="text"]:focus, input[type="password"]:hover, input[type="password"]:focus, textarea:hover, textarea:focus {
            border: 1px #c80a0a solid;
        }

        #highscore-list {
        }

        #score {
            float: right;
        }

        #myCanvas {
            border: none;
            display: block;
            background: rgba(0, 0, 0, 0);
            width: 100%;
            height: auto;
        }

        #canvas-container {
            background-color: rgba(0, 0, 0, 0.025);
            transition: background-color 0.2s ease;
            position: relative;
            //margin: 0 1rem;
        }

        #canvas-overlay-container {
            position: absolute;
            height: 100%;
            width: 540px;
            max-width: 100%;
            text-align: center;
        }

        #canvas-overlay-content {

            position: absolute;
            top: 33%;
            left: 50%;
            background: red;
            color: white;
            /* right: initial; */
            /* left: initial; */
            padding: 21px;
            transform: translateX(-50%);

        }

        #canvas-overlay-content{
            #title {

            text-transform: uppercase;
            font-size: 24px;
            }

            #text {
                font-size: 16px;
            }
        }

        .canvas {
            position: absolute;
            padding-top: 20px;
        }

        .main {
            width: 100%;
            max-width: 660px;
            margin: 10px auto;
            position: static;
            left: auto;
        }

        .content {
            width: 100%;
            max-width: 540px;
            text-align: left;
            margin: 0;
        }

        .container {
            text-align: center;
            margin: 0;
            padding: 0;
        }

        .wrapper {
            padding: 0;
            margin: auto 1rem;
        }

        .game {
            max-width: 540px;
            @media screen and (min-width: 572px) {
                margin: auto;
            }
        }

        .about {
            font-size: 10px;
            margin-top: 20px;
            text-align: center;
            display: none;
        }

        div.audio {
        }

        audio {
            display: block;
        }

        .score {
            width: 130px;
        }

        .score, .lives, .level {
            display: inline-block;

            div {
                display: inline-block;
            }
        }

        .score, .level {
            div {
                position: relative;
                top: 3px;
                &::after, &::before {
                    content: '';
                    background: white;
                    position: absolute;
                    z-index: -1;
                    top: -6px;
                    bottom: -6px;
                    left: 0;
                    right: 0;
                }

                &::before {
                    top: 0;
                    bottom: 0;
                    left: -6px;
                    right: -6px;
                }
            }
        }

        .level {
            margin-right: 10px;
        }

        .lives {

            &-hearts {
                font-size: 4px;
                margin-left: -10px;
            }

            img {
                width: auto;
                margin-top: 0;
                padding-top: 0;
                top: 3px;
                position: relative;
            }
        }

        /* ---------- Controls ------------------------*/


        /* Game Menu - displayed when game is paused */
        #menu-buttons ul, .controls#menu-buttons ul li {
            list-style: none;
            padding: 0;
        }

        #menu-buttons ul li {
            height: 40px;
            line-height: 40px;
        }


        .description {
            display: none;
        }


        @media screen and (max-width: 400px) {

            .main {
                margin: 10px auto;
            }
            .content {
                min-height: 0;
            }
        }
    }

}