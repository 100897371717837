body.hack {

    @media screen and (min-width: 680px) {
        footer {
            min-height: 250px;
        }
    }
    @media screen and (min-width: 900px) {
        .pacman-container__game {
            padding-bottom: 110px;
        }
    }
    @media screen and (min-width: 1440px) {
        .headerLogo {
            padding-bottom: 20px;
        }
    }
}


.pacman-container {


    .messageHeader {
        position: relative;
        margin: ($headerHeightMobile +15px) auto 20px auto;
        padding: 20px;
        padding-bottom: 0;
        max-width: 716px;
        text-align: center;

        @media screen and (min-width: 960px) {
            margin-top: $headerHeightDesktop;
            padding-bottom: 7vh;
            padding-top: 8vh;
        }


        h1 {
            font-size: 1.8rem;
            margin-bottom: 1rem;

            @media (min-width: 960px) {
                font-size: 2rem;
                margin-bottom: 2rem;
            }

            @media (min-width: 1280px) {
                font-size: 2.25rem;
            }
        }
    }

    .btnOutline {
        margin-bottom: 3.25rem;
    }

    .pacman-container {
        &__top-bar__info {
            .lives {
                //background-color: red;
                &::after {
                    content: '';
                    display: block;
                    position: relative;
                    height: 0;
                    width: 90vw;
                }

            }
        }

        &__bottom-bar {
            display: flex;
            width: 100%;
            flex-direction: column;
            @media screen and (min-width: 900px) {
                flex-direction: row;
            }

            &__dir {
                width: calc((100% - 540px) / 2);

                .cover {
                    display: none;
                }

                order: 2;
                @media screen and (max-width: 900px) {
                    width: 100%;
                    margin-bottom: 4.625rem;

                }
                @media screen and (min-width: 900px) {
                    order: 1;
                }
            }

            &__info {
                transition: height 1s ease;
                overflow: hidden;
                margin: 1rem;
                width: calc(100% - 2rem );
                justify-content: center;
                display: flex;
                order: 1;

                &.nomobile {
                    display: flex !important;
                }

                &-inner {
                    padding-top: 4px;
                }

                &-label {
                    font-size: 8px;
                }

                @media screen and (min-width: 900px) {
                    width: 540px;
                    order: 2;
                    margin-top: 60px;
                }
            }

            &__button {
                order: 3;
                width: calc((100% - 540px) / 2);

                button {

                    max-width: 260px;
                }

                text-align: center;
            }

        }

    }

    .mobile {
        display: none !important;
    }

    .nomobile {
        display: block !important;
    }


    &.paused {
        .pacman-container {
            &__bottom-bar {
                &__info {
                    height: 0;
                }

                &__dir {
                    position: relative;

                    .cover {
                        position: absolute;
                        top: -0;
                        height: 100%;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        z-index: 1000;
                        display: block;
                    }

                }
            }
        }
    }


    @media screen and (max-width: 900px) {

        .nomobile {
            display: none !important;
        }
        .mobile {
            display: block !important;
        }
    }

}