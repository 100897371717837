.controls {
    text-align: center;
    margin-top: 15px;
    display: flex;
    flex-direction: column;

    &__top {

    }
    &__bottom {
        display: flex;
        justify-content: center;
    }
}

.controls-section {
    height: auto;
    width: 47%;
    display: inline-block;
    margin: 0;
    text-align: left;
}

.controls-left {
}

.controls-right .controlButton {
    position: relative;
    right: -65%;
}

/* Arrow Keys - only displayed when game isn't paused */
#game-buttons {
    //display: none;
}


.controlSound {
    background: url('#{$pacmanRootUrl}img/audio-icon.png');
    position: relative;
    bottom: 5px;
    background-size: 100%;
    height: 25px;
    width: 36px;
    float: right;
    top: 0;
}

#mute {
    display: none;
    width: 36px;
    height: 25px;
    margin: 0;
}

.controlButton {
    display: inline-block;
    width: 50px;
    height: 50px;
    //margin: 5px;
    //background: none repeat scroll 0% 0% #C80A0A;
    line-height: 50px;
    font-weight: bold;
    font-size: 50px;
    cursor: pointer;
    border: 1px #373F41 solid;
    position: relative;

    &:after {
        content:'';
        height: 50px;
        width: 50px;
        display: block;
        position: absolute;

        background: url('#{$pacmanRootUrl}/img/arrow-control.svg') no-repeat;
        background-position: center;

    }
    &:before{
        content:'';
        position: absolute;
        display: block;
        height: 0;
        width: 0;
        //border: 0 #AFB2B3 solid;

        background-color: #373F41;
        transition: height 0.25s ease,width 0.25s ease, background-color 0.25s ease, border 0.25s ease;
        bottom: 0;
    }

    &:hover {
        &:after {
            background-image: url('#{$pacmanRootUrl}/img/arrow-control-empty.svg');
        }

        &:before {
            background-color: white;
            border-width: 1px;

        }
    }


    &#up {
        margin-bottom: -2px;
        &:before {
            bottom:0;
            height: 0;
            width: 48px;
        }
        &:hover {
            &:before {
                height: 48px;
                border-bottom-width: 0;
            }
        }

    }
    &#left {
        &:after {
            transform: rotate(270deg);
        }
        &:before {
            right:0;
            height: 48px;
            width: 0;
        }
        &:hover {
            &:before {
                width:48px;
                border-right-width: 0;
            }
        }
    }
    &#down {
        margin-left: -1px;
        margin-right: -1px;
        &:after {
            transform: rotate(180deg);
        }
        &:before {
            top:0;
            height: 0;
            width: 48px;
        }
        &:hover {
            &:before {
                height: 48px;
                border-top-width: 0;
            }
        }
    }
    &#right {
        &:after {
            transform: rotate(90deg);
        }
        &:before {
            left:0;
            height: 48px;
            width: 0;
        }
        &:hover {
            &:before {
                width:48px;
                border-left-width: 0;
            }
        }
    }
}

.controlButton:hover, .controlButton:active {
    //background: #c00202;
}

@media screen and (max-width: 400px) {
    #game-buttons {
      width: 100%;
        align-items: center;
    }

    .controls-right .controlButton {
        right: -55%;
    }

    .controlButton {
        width: 60px;
        height: 60px;
        line-height: 40px;
        font-size: 40px;


        &:after {
            height: 60px;
            width: 60px;
        }

        &#up {
            &:before {
                width: 58px;
            }
            &:hover {
                &:before {
                    height: 58px;
                }
            }

        }
        &#left {
            &:before {
                height: 58px;
            }
            &:hover {
                &:before {
                    width:58px;
                }
            }
        }
        &#down {
            margin-left: -1px;
            margin-right: -1px;

            &:before {
                width: 58px;
            }
            &:hover {
                &:before {
                    height: 58px;
                }
            }
        }
        &#right {
            &:before {
                height: 58px;
            }
            &:hover {
                &:before {
                    width:58px;
                }
            }
        }

    }
}